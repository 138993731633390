
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {
        private data() {
            return {
                headsetIconPathAndName:
                    require("@/assets/images/icon_headset.png"),

                unityIconPathAndName:
                    require("@/assets/images/unity.png"),

                image01:
                    require("@/assets/images/unity-dev-district-texture-baker-01.png"),
                image02:
                    require("@/assets/images/unity-dev-district-texture-baker-02.png"),
                image03:
                    require("@/assets/images/unity-dev-district-texture-baker-03.png"),
                image04:
                    require("@/assets/images/unity-dev-district-texture-baker-04.png"),
                image05:
                    require("@/assets/images/unity-dev-district-texture-baker-05.png"),
                image06:
                    require("@/assets/images/unity-dev-district-texture-baker-06.png"),
                image07:
                    require("@/assets/images/unity-dev-district-texture-baker-07.png"),
                image08:
                    require("@/assets/images/unity-dev-district-texture-baker-08.png"),
                image09:
                    require("@/assets/images/unity-dev-district-texture-baker-09.png"),
                image10:
                    require("@/assets/images/unity-dev-district-texture-baker-10.png"),
                image11:
                    require("@/assets/images/unity-dev-district-texture-baker-11.png"),
                image12:
                    require("@/assets/images/unity-dev-district-texture-baker-12.png"),
                image13:
                    require("@/assets/images/unity-dev-district-texture-baker-13.png"),
                image14:
                    require("@/assets/images/unity-dev-district-texture-baker-14.png"),
                image15:
                    require("@/assets/images/unity-dev-district-texture-baker-15.png"),
                image16:
                    require("@/assets/images/unity-dev-district-texture-baker-16.png"),
                image17:
                    require("@/assets/images/unity-dev-district-texture-baker-17.png"),
            };
        }
    }
